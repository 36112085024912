
import Vue from "vue"
import SnapshotPreview from "@evercam/shared/components/SnapshotPreview"
import EventsUtils from "@/components/gateReport/mixins/eventsUtils"
import { useGateReportStore } from "@/stores/gateReport"
import { mapStores } from "pinia"
import { Camera, GateReportEventDirection } from "@evercam/shared/types"
import { getConvertedUtcDateTimetoTimezone } from "@evercam/shared/utils"
import { useProjectStore } from "@/stores/project"
import { useAccountStore } from "@/stores/account"

export default Vue.extend({
  name: "EventThumbnail",
  components: {
    SnapshotPreview,
  },
  mixins: [EventsUtils],
  inject: ["event", "projectCameras"],
  props: {
    height: {
      type: Number,
      required: false,
      default: 100,
    },
    isContext: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      error: false,
      loaded: false,
      thumbnailHeight: null,
    }
  },
  computed: {
    ...mapStores(useGateReportStore, useProjectStore, useAccountStore),
    noImage(): boolean {
      return this.error || !this.event.eventTime
    },
    title(): string {
      const eventTypeVerbs = {
        [GateReportEventDirection.In]: "Entering",
        [GateReportEventDirection.Out]: "Exiting",
      }

      return `${
        eventTypeVerbs[this.event.direction] || ""
      } ${this.gateReportStore.getVehicleLabel(
        this.event.vehicleType
      )} - ${getConvertedUtcDateTimetoTimezone(
        this.event.eventTime,
        this.projectStore.selectedProjectTimezone
      )}`
    },
    thumbnailWidth(): number | null {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return null
        case "sm":
          return 150
        case "md":
          return 230
        case "lg":
          return 200
        case "xl":
          return 250
      }

      return null
    },
    camera(): Camera {
      return this.projectCameras[
        this.isContext ? this.event?.contextCamera : this.event?.cameraExid
      ]
    },
    thumbnailUrl(): string {
      return this.isContext
        ? this.event?.contextThumbnailUrl
        : this.event?.thumbnailUrl
    },
  },
  watch: {
    loaded() {
      if (this.loaded) {
        // Get the reference to the thumbnail element
        this.thumbnailHeight =
          this.$refs[`thumbnail-${this.event.id}`].$el.offsetHeight
        this.$emit("thumbnailLoaded", this.thumbnailHeight)
      }
    },
  },
})
