
import { useGateReportStore } from "@/stores/gateReport"
import { mapStores } from "pinia"
export default {
  name: "CalendarLeaf",
  props: {
    inCount: {
      type: Number,
      default: 0,
    },
    outCount: {
      type: Number,
      default: 0,
    },
    unknownCount: {
      type: Number,
      default: 0,
    },
    status: {
      type: String,
      default: "",
    },
  },
  computed: {
    ...mapStores(useGateReportStore),
    hasData() {
      return Math.max(this.inCount, this.outCount)
    },
    isProcessed() {
      return (
        this.gateReportStore.isAnprLegacy ||
        this.gateReportStore.showAnprBasedGateReport ||
        this.status === this.gateReportStore.processingStatus.processed
      )
    },
  },
}
