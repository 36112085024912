
import { debounce } from "@evercam/shared/utils"
import { mapStores } from "pinia"
import { useGateReportStore } from "@/stores/gateReport"
import { useProjectStore } from "@/stores/project"
import { AnalyticsEvent } from "@evercam/shared/types/analytics"
import { GateReportEventType } from "@evercam/shared/types"

export default {
  name: "InputFitlers",
  computed: {
    ...mapStores(useGateReportStore, useProjectStore),
    isAnprReport() {
      return (
        this.gateReportStore.showAnprBasedGateReport ||
        this.gateReportStore.isAnprLegacy
      )
    },
    sortOptions() {
      const options = [
        { text: "Event time asc", value: "event_time|asc" },
        { text: "Event time desc", value: "event_time|desc" },
        { text: "Gate asc", value: "cameraex|asc" },
        { text: "Gate desc", value: "cameraex|desc" },
        { text: "Plate number asc", value: "plate_number|asc" },
        { text: "Plate number desc", value: "plate_number|desc" },
      ]

      if (this.gateReportStore.isAnprLegacy) {
        return options
      }

      return [
        ...options,
        { text: "Vehicle type asc", value: "context_vehicle_type|asc" },
        { text: "Vehicle type desc", value: "context_vehicle_type|desc" },
      ]
    },
    hasMultipleCameras() {
      return this.gateReportStore.cameras?.length > 1
    },
    allCamerasSelected() {
      return (
        this.gateReportStore.selectedCameras?.length ===
        this.gateReportStore.cameras?.length
      )
    },
    allVehicleTypesSelected() {
      return (
        this.gateReportStore.filteredVehicleTypes &&
        this.gateReportStore.filteredVehicleTypes.length ===
          this.gateReportStore.vehicleTypes.length
      )
    },
    allEventTypesSelected() {
      return (
        this.gateReportStore.selectedEventTypes.length ===
        this.eventTypes.length
      )
    },
    eventTypes() {
      return [
        {
          id: GateReportEventType.Arrived,
          name: "Vehicle Entry",
          disabled:
            this.gateReportStore.selectedEventTypes?.length === 1 &&
            this.gateReportStore.showInEvents,
        },
        {
          id: GateReportEventType.Left,
          name: "Vehicle Exit",
          disabled:
            this.gateReportStore.selectedEventTypes?.length === 1 &&
            this.gateReportStore.showOutEvents,
        },
      ]
    },
    selectedSort: {
      get() {
        return this.gateReportStore.sort
      },
      set(value) {
        this.gateReportStore.sort = value
        this.handleSortChange()
      },
    },
    plateNumber: {
      get() {
        return this.gateReportStore.plateNumber
      },
      set(value) {
        this.gateReportStore.plateNumber = value
        this.handlePlateNumberChange()
      },
    },
    specialLgWidth() {
      let width = this.$vuetify.breakpoint.width

      return width > 1263 && width < 1555
    },
    specialMdWidth() {
      let width = this.$vuetify.breakpoint.width

      return width > 959 && width < 1076
    },
    specialSmWidth() {
      let width = this.$vuetify.breakpoint.width

      return width > 599 && width < 700
    },
  },
  methods: {
    toggleAllCameras() {
      if (this.allCamerasSelected) {
        this.gateReportStore.selectedCameras = []
      } else {
        this.gateReportStore.selectedCameras =
          this.gateReportStore.cameras?.map((camera) => camera.id)
      }
      this.$analytics.saveEvent(AnalyticsEvent.FilterGateReportCameras)
      this.handleCameraChange()
    },
    toggleAllVehicleTypes() {
      if (this.allVehicleTypesSelected) {
        this.gateReportStore.selectedVehicleTypes = []
      } else {
        this.gateReportStore.selectedVehicleTypes =
          this.gateReportStore.vehicleTypeIds
      }
      this.$analytics.saveEvent(AnalyticsEvent.FilterGateReportVehicleTypes)
      this.handleVehicleTypeChange()
    },
    handleCameraChange: debounce(function () {
      this.$analytics.saveEvent(AnalyticsEvent.FilterGateReportCameras)
      this.$emit("filters-change")
    }, 500),

    handleVehicleTypeChange: debounce(function () {
      this.$analytics.saveEvent(AnalyticsEvent.FilterGateReportVehicleTypes)
      this.$emit("filters-change")
    }, 500),
    handleEventTypesChange() {
      this.$analytics.saveEvent(AnalyticsEvent.FilterGateReportEntryExit)
      this.$emit("filters-change")
    },
    handlePlateNumberChange: debounce(function () {
      this.$analytics.saveEvent(AnalyticsEvent.FilterGateReportPlateNumber)
      this.$emit("filters-change")
    }, 500),
    handleSortChange() {
      this.$emit("sort-change", this.selectedSort)
    },
    getIconStyle(vehicleId) {
      return this.gateReportStore.selectedVehicleTypes.includes(vehicleId)
        ? "selected"
        : "unselected"
    },
  },
}
