
import { getFormattedPlateNumber } from "@evercam/shared/utils"

export default {
  name: "PlateNumber",
  props: {
    value: {
      type: String,
      default: "",
    },
  },
  computed: {
    formattedPlateNumber() {
      return getFormattedPlateNumber(this.value)
    },
  },
}
