export default (self) => [
  {
    width: 120,
    visible: self.gateReportStore.showThumbnails,
    divider: true,
    sortable: false,
    text: self.$t("content.gate_report.thumbnail"),
    value: "thumbnail",
    class: "header--custom",
  },
  {
    width: 100,
    visible: true,
    divider: true,
    sortable: false,
    text: self.$t("content.gate_report.plate_number"),
    value: "plateNumber",
    class: "header--custom",
  },
  {
    width: 120,
    divider: true,
    visible: true,
    sortable: false,
    text: self.$t("content.gate_report.event_type"),
    value: "eventType",
    class: "header--custom",
  },
  {
    width: 120,
    divider: true,
    visible: true,
    sortable: false,
    text: self.$t("content.gate_report.event_time"),
    value: "eventTime",
    class: "header--custom",
  },
  {
    visible: true,
    divider: true,
    sortable: false,
    text: self.$t("content.gate_report.camera"),
    value: "camera",
    class: "header--custom",
  },
]
