
import RightSidebarContent from "@/components/portals/RightSidebarContent"
import GateReportSidebar from "@/components/gateReport/GateReportSidebar"
import EventsTable from "@/components/gateReport/eventsTable/EventsTable"
import EventsTableLoader from "@/components/gateReport/skeletonLoaders/EventsTableLoader"
import CountsTimeline from "@/components/gateReport/CountsTimeline"
import { useGateReportStore } from "@/stores/gateReport"
import { useLayoutStore } from "@/stores/layout"
import { mapStores } from "pinia"

export default {
  name: "EventsReportContainer",
  components: {
    RightSidebarContent,
    GateReportSidebar,
    EventsTable,
    EventsTableLoader,
    CountsTimeline,
  },
  props: {
    isFetching: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapStores(useGateReportStore, useLayoutStore),
  },
  mounted() {
    this.layoutStore.enableRightSidebar({ width: 330 })
  },
  beforeDestroy() {
    this.layoutStore.disableRightSidebar()
  },
}
