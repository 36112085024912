import { useGateReportStore } from "@/stores/gateReport"
import { mapStores } from "pinia"
import { AiApi, getAiApiUrl } from "@evercam/shared/api/aiApi"
import { useProjectStore } from "@/stores/project"
import { useAccountStore } from "@/stores/account"
import axios from "@evercam/shared/api/client/axios"

export default {
  name: "EventsUtils",
  computed: {
    ...mapStores(useGateReportStore, useProjectStore),
  },
  methods: {
    getFormattedEvent(event) {
      return {
        ...event,
        label: this.gateReportStore.getVehicleLabel(event.truckType),
        thumbnailUrl: this.getThumbnailUrl(event.mainSnapshotLink),
        plateThumbnailUrl: this.getPlateThumbnailUrl(event),
        contextThumbnailUrl: this.getThumbnailUrl(event.contextSnapshotLink),
        mp4Url: this.getMP4Url(event.id, event.cameraExid),
      }
    },
    getEventThumbnailUrl(event) {
      return AiApi.gateReport.getEventThumbnail({
        cameraExid: event.cameraExid,
        eventType: event.eventType,
        eventTime: event.eventTime,
        token: useAccountStore().token,
      })
    },
    getThumbnailUrl(snapshotLink) {
      if (!snapshotLink) {
        return
      }

      const url = `${getAiApiUrl()}${snapshotLink}?authorization=${
        useAccountStore().token
      }`

      return this.$imgproxy.get720pResizedImageUrl(url)
    },
    getPlateThumbnailUrl(event) {
      if (!event.plateNumber) {
        return
      }

      return `${this.$config.public.apiURL}/cameras/${event.cameraExid}/anpr/${
        event.id
      }/thumbnail?authorization=${useAccountStore().token}`
    },
    getProcessedTimestamp(timestamp, format) {
      return this.$moment
        .utc(timestamp)
        .tz(this.projectStore.selectedProjectTimezone)
        ?.format(format || "YYYY-MM-DDTHH:mm:ss")
    },
    getMP4Url(eventId, cameraExid) {
      return `${axios.env.baseUrl}/cameras/${cameraExid}/anpr/${eventId}/clip`
    },
  },
}
