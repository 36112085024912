
import { useGateReportStore } from "@/stores/gateReport"
import { mapStores } from "pinia"

const TextColumns = {
  AnprLegacy: 4,
  AnprBasedGateReport: 2,
  Default: 3,
}

const ImageColumns = {
  LegacyReport: 1,
  Default: 2,
}

export default {
  data() {
    return {
      height: "100%",
    }
  },
  computed: {
    ...mapStores(useGateReportStore),
    tableTextCols() {
      if (this.gateReportStore.isAnprLegacy) {
        return TextColumns.AnprLegacy
      }

      if (this.gateReportStore.showAnprBasedGateReport) {
        return TextColumns.AnprBasedGateReport
      }

      return TextColumns.Default
    },
    tableImageCols() {
      if (
        this.gateReportStore.isLegacySelectedDate ||
        this.gateReportStore.isAnprLegacy
      ) {
        return ImageColumns.LegacyReport
      }

      return ImageColumns.Default
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.onResize()
    })
  },
  methods: {
    onResize() {
      const padding = 20
      const tableContainerTopPosition =
        this.$refs.tableContainer?.getBoundingClientRect().top
      const newHeight = window.innerHeight - tableContainerTopPosition
      this.height = `${newHeight - padding}px`
    },
  },
}
