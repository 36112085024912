
export default {
  name: "ExportcolumnsSelector",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    isAnpr: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      anprColumns: [
        { value: "anpr_thumbnail", text: "Anpr Thumbnail" },
        { value: "context_thumbnail", text: "Context Thumbnail" },
        { value: "event_time", text: "Event Time" },
        { value: "event_type", text: "Direction" },
        { value: "plate_number", text: "Plate Number" },
        { value: "vehicle_type", text: "Vehicle Type" },
        { value: "vehicle_make", text: "Vehicle Make" },
        { value: "vehicle_model", text: "Vehicle Model" },
        { value: "vehicle_color", text: "Vehicle Color" },
      ],
      gateReportLegacyColumns: [
        { value: "context_thumbnail", text: "Thumbnail" },
        { value: "event_time", text: "Event Time" },
        { value: "event_type", text: "Direction" },
        { value: "vehicle_type", text: "Vehicle Type" },
      ],
      selectedColumns: [],
    }
  },
  computed: {
    dialog: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit("input", value)
      },
    },
    columns() {
      if (this.isAnpr) {
        return this.anprColumns
      }

      return this.gateReportLegacyColumns
    },
  },
  watch: {
    dialog: {
      handler(value) {
        if (value) {
          this.selectedColumns = this.columns.map((item) => item.value)
        }
      },
      immediate: true,
    },
  },
}
