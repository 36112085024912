
import PlateNumber from "@/components/anpr/PlateNumber"
import EventsUtils from "@/components/gateReport/mixins/eventsUtils"
import PreviewableThumbnail from "@/components/PreviewableThumbnail"
import EventTimeCell from "@/components/gateReport/eventsTable/EventTimeCell"

import { useAccountStore } from "@/stores/account"
import { useProjectStore } from "@/stores/project"
import { mapStores } from "pinia"

export default {
  name: "AnprLegacyTableRow",
  components: {
    PlateNumber,
    PreviewableThumbnail,
    EventTimeCell,
  },
  mixins: [EventsUtils],
  inject: [
    "event",
    "eventType",
    "eventTypeIconStyle",
    "eventPlateNumber",
    "projectCameras",
    "headers",
  ],
  data() {
    return {
      rowHeight: 100,
    }
  },
  computed: {
    ...mapStores(useProjectStore, useAccountStore),
    eventCameraName() {
      return this.projectCameras?.[this.event?.cameraExid]?.name
    },
    eventCamera() {
      return this.projectCameras?.[this.event?.cameraExid]
    },
  },
  methods: {
    updateRowHeight(height) {
      this.rowHeight = height
    },
  },
}
