
import ExportAsBtn from "@/components/gateReport/ExportAsBtn"
import { useGateReportStore } from "@/stores/gateReport"
import { mapStores } from "pinia"
import { useProjectStore } from "@/stores/project"

export default {
  components: {
    ExportAsBtn,
  },
  computed: {
    ...mapStores(useGateReportStore, useProjectStore),
  },
}
