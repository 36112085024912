
import EventsUtils from "@/components/gateReport/mixins/eventsUtils"
import SnapshotPreview from "@evercam/shared/components/SnapshotPreview"
import { getConvertedUtcDateTimetoTimezone } from "@evercam/shared/utils"

export default {
  name: "PreviewableThumbnail",
  components: {
    SnapshotPreview,
  },
  mixins: [EventsUtils],
  props: {
    thumbnailUrl: {
      type: String,
      default: "",
    },
    timestamp: {
      type: String,
      default: "",
    },
    plateNumber: {
      type: String,
      default: "",
    },
    authToken: {
      type: String,
      default: "",
    },
    camera: {
      type: Object,
      required: true,
    },
    project: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isThumbnailLoaded: false,
      imgRect: {},
      error: false,
    }
  },
  computed: {
    timezone() {
      return this.project.timezone || "Europe/Dublin"
    },
    overlayStyle() {
      return {
        height: `${this.imgRect.height}px`,
        width: `${this.imgRect.width}px`,
      }
    },
    title() {
      return `${this.plateNumber} - ${getConvertedUtcDateTimetoTimezone(
        this.timestamp,
        this.timezone
      )}`
    },
  },
  methods: {
    onImageLoaded() {
      this.isThumbnailLoaded = true
      this.$nextTick(() => {
        this.imgRect = this.$refs.img?.getBoundingClientRect() || {}
      })
    },
  },
}
