
import { mapStores } from "pinia"
import { useGateReportStore } from "@/stores/gateReport"
import InputFilters from "@/components/gateReport/InputFilters"
import Actions from "@/components/gateReport/Actions"
import CalendarTreeLoader from "@/components/gateReport/skeletonLoaders/CalendarTreeLoader"
import CalendarTree from "@/components/gateReport/CalendarTree"
import axios from "@evercam/shared/api/client/axios"
export default {
  name: "GateReportSidebar",
  components: { Actions, InputFilters, CalendarTreeLoader, CalendarTree },
  props: {
    isFetching: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      height: "auto",
    }
  },

  computed: {
    ...mapStores(useGateReportStore),
    treeContainerStyle() {
      return {
        height: this.height + "px",
      }
    },
  },
  mounted() {
    this.$addEventListener("resize", this.onResize)
    this.$nextTick(() => {
      this.onResize()
    })
  },
  methods: {
    handleFiltersChange() {
      axios.cancelRequests()
      this.gateReportStore.options = {
        ...this.gateReportStore.options,
        page: 1,
      }
      this.gateReportStore.fetchEvents()
      this.gateReportStore.fetchEventCounts()
    },
    handleSortChange(sort) {
      axios.cancelRequests()
      this.gateReportStore.sort = sort
      this.gateReportStore.fetchEvents()
    },
    updateSelectedDate(date) {
      const isValidDate = this.$moment(date, "YYYY-MM-DD", true).isValid()
      if (!isValidDate || !date || date === this.gateReportStore.selectedDate) {
        return
      }
      this.$analytics.saveEvent("click_gate_report_calendar_tree")
      this.gateReportStore.selectedDate = date
      this.$emit("date-change")
    },
    onResize() {
      const treeContainerTop =
        this.$refs.treeContainer?.$el?.getBoundingClientRect().top
      this.height = window.innerHeight - treeContainerTop
    },
  },
}
