import { render, staticRenderFns } from "./CalendarLeaf.vue?vue&type=template&id=55b9f287"
import script from "./CalendarLeaf.vue?vue&type=script&lang=js"
export * from "./CalendarLeaf.vue?vue&type=script&lang=js"
import style0 from "./CalendarLeaf.vue?vue&type=style&index=0&id=55b9f287&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports