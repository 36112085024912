
import Vue, { PropType } from "vue"
import { AdminCamera, Camera, AnalyticsEvent } from "@evercam/shared/types"
import CameraCard from "@evercam/shared/components/CameraCard"

export default Vue.extend({
  name: "TimelinePlayerCameraSelector",
  components: { CameraCard },
  props: {
    cameras: {
      type: Array as PropType<Array<Camera | AdminCamera>>,
      required: true,
    },
    selectedCamera: {
      type: Object as PropType<Camera | AdminCamera>,
      required: true,
    },
    targetTimestamp: {
      type: [String],
      default: undefined,
    },
    token: {
      type: String,
      required: true,
    },
    itemWidth: {
      type: Number,
      default: 100,
    },
  },
  data() {
    return {
      isMouseOver: false,
      isExpanded: false,
      hasScrollbar: false,
    }
  },
  computed: {
    containerStyle(): Record<string, any> {
      return {
        right: "22px",
      }
    },
    dropDownContainerStlye(): Record<string, any> {
      const minWidth = this.hasScrollbar ? this.itemWidth + 40 : this.itemWidth

      return {
        minWidth: `${minWidth}px !important`,
        float: "right",
      }
    },
  },
  mounted() {
    this.calculateContainerScrollState()
  },
  methods: {
    isCameraSelected(camera: Camera | AdminCamera): boolean {
      if (!camera) {
        return
      }

      return camera.exid === this.selectedCamera.exid
    },
    onContainerMouseOver() {
      if (this.$device.isMobile) {
        return
      }
      this.isMouseOver = true
      this.$setTimeout(() => {
        if (!this.isMouseOver) {
          return
        }

        this.calculateContainerScrollState()
        this.isExpanded = true
      }, 500)
    },
    onContainerMouseLeave() {
      this.isMouseOver = false
      this.$setTimeout(() => {
        if (this.isMouseOver) {
          return
        }

        this.isExpanded = false
      }, 600)
    },
    calculateContainerScrollState() {
      const expandedHeight = 550
      const contentHeight = this.$refs.content?.getBoundingClientRect()?.height
      this.hasScrollbar = contentHeight > expandedHeight
    },
    toggleMenuForMobileDevices() {
      if (!this.$device.isMobile) {
        return
      }
      this.isExpanded = !this.isExpanded
    },
    onCameraClick(c) {
      this.$emit("change", c)
      this.$root.$emit("analytics-event", {
        eventId: AnalyticsEvent.PlayerSelectCamera,
        params: { cameraId: c.exid },
      })
    },
  },
})
